import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import { ModalInformations } from './Informations';


export const Home = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <div id="home">
            <Link to="/" className="logo"><Image src="images/logo.png" /></Link>
            <Button className="infos_button" variant="outline-light"onClick={() => setModalShow(true)}>Présentation</Button>
            <div className="bloc_texte">
                <div className="texte_1">JE CONSTATE UN<br /><span className="texte-bold">TRAFIC DE <span className="texte-red">TABAC</span></span>.</div>
                <div className="texte_2">J'ALERTE<br/>IMM&#201;DIATEMENT<br /><span className="texte-bold">LES DOUANES</span>.</div>
            </div>
            <div className="bloc_bouton">
                <div className="bouton_home bouton_observation_physique" onClick={() => props.selectForm('physique') }></div>
                <br />
                <div className="bouton_home bouton_observation_internet" onClick={() => props.selectForm('internet') }></div>
            </div>
            <div className="texte_3">NE LAISSEZ PAS LE TRAFIC S'INSTALLER SUR VOTRE TERRITOIRE. <b>D&#201;NONCEZ-LE</b></div>
            
            <ModalInformations show={modalShow} onHide={() => setModalShow(false)} />
                    
        </div>
    );
}