import React from 'react';
import { Field } from 'formik';
import classNames from "classnames";

// Input feedback
const InputFeedback = ({ error }) =>
  error ? <div className={classNames("text-danger")}>{error}</div> : null;

// Form Section
export const FormSection = props => (
    <div className={classNames("form-section mb-5", props.classes)}>    
        <h4 className={classNames("form-section-title", props.icon)}>
            <span>{props.label}</span>
        </h4>
        {props.children}
    </div>        
);

// Form Group
export const FormGroup = props => (
    <div className={classNames("form-group row", props.classes)}>
        <label className="col-md-3 control-label" htmlFor={props.id}>{props.label}{props.required && <span className="required">*</span>}</label>
        <div className="col-md-9">
            {props.children}
        </div>
    </div>
);

// Checkbox input
export const Checkbox = ({ field: { name, value, onChange, onBlur }, form: { errors, touched }, id, label, className, ...props }) => {
  return (
    <div className="form-check form-check-inline">
      <input type="checkbox" name={name} id={id} value={value} checked={value} onChange={onChange} onBlur={onBlur} className={classNames("checkbox form-check-input", className)} {...props} />
      {' '}<label className="form-check-label" htmlFor={id}>{label}</label>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </div>
  );
};

// Checkbox group
export class CheckboxGroup extends React.Component {

    handleChange = event => {
        const target = event.currentTarget;
        let valueArray = [...this.props.value] || [];

        if (target.checked) {
            valueArray.push(target.id);
        } else {
            valueArray.splice(valueArray.indexOf(target.id), 1);
        }

        this.props.onChange(this.props.id, valueArray);
    };

    handleBlur = () => {
        this.props.onBlur(this.props.id, true);
    };

    render() {
        const { value, error, touched, className, children } = this.props;

        const classes = classNames({"is-success": value || (!error && touched), "is-error": !!error && touched}, className);

        return (
            <div className={classes}>
                {React.Children.map(children, child => {
                    return React.cloneElement(child, {
                        field: {
                            value: value.includes(child.props.id),
                            onChange: this.handleChange,
                            onBlur: this.handleBlur
                        }
                    });
                })}
                {touched && <InputFeedback error={error} />}
            </div>
        );
    }
};

// Checkbox group from array
/*export const CheckboxGroupFromArray = ({value, error, touched, name, className, elements, separator}) => {
    return (
        <CheckboxGroup value={value} error={error} touched={touched} className={className}>
            {elements.map( element => (
                <span>
                    { typeof element === 'Object' ? 
                        <Field component="Checkbox" name={name} id={name + '_' + element.id} label={element.name} />
                        :
                        <Field component="Checkbox" name={name} id={element} label={element} />
                    }
                    {separator}
                </span>
            ))}
        </CheckboxGroup>
    );
};*/

// Radio input
/*export const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => {
    return (
        <div className="form-check form-check-inline">
            <input type="radio" name={name} id={id} value={id} checked={id === value} onChange={onChange} onBlur={onBlur} className={classNames("radio-button form-check-input", className)} {...props} />
            {' '}<label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
};*/
export const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, className, cb_value, ...props }) => {
    return (
        <div className="form-check form-check-inline">
            <input type="radio" name={name} id={id} value={cb_value} checked={cb_value === value} onChange={onChange} onBlur={onBlur} className={classNames("radio-button form-check-input", className)} {...props} />
            {' '}<label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
};

// Radio group
export const RadioButtonGroup = ({value, error, touched, className, children}) => {
    const classes = classNames(
        {
            "is-success": value || (!error && touched), // handle prefilled or user-filled
            "is-error": !!error && touched
        },
        className
    );

    return (
        <div className={classes}>
            {children}
            {touched && <InputFeedback error={error} />}
        </div>
    );
};


// Radio group from object
/*export const RadioButtonGroupFromObject = ({value, error, touched, name, className, elements, separator}) => {
    return (
        <RadioButtonGroup value={value} error={error} touched={touched} className={className}>
            {elements.map( ({id_el, name_el}) => (
                <span key={id_el}>
                    <Field component={RadioButton} name={name} id={name + '_' + id_el} label={name_el} />
                    {separator}
                </span>
            ))}
        </RadioButtonGroup>
    );
};*/

// Radio group from object
export const RadioButtonGroupFromArray = ({value, error, touched, name, className, elements, separator}) => {
    return (
        <RadioButtonGroup value={value} error={error} touched={touched} className={className}>
            {elements.map( (cb_value, index) => (
                <span key={name + '_' + index}>
                    <Field component={RadioButton} name={name} id={name + '_' + index} label={cb_value} cb_value={cb_value} />
                    {separator === 'line' ? <br /> : <span>&nbsp;&nbsp;&nbsp;</span> }
                </span>
            ))}
        </RadioButtonGroup>
    );
};

// Radio group yes/no
export const RadioButtonGroupYesNo = ({value, error, touched, id, name, className}) => {
    return (
        <RadioButtonGroupFromArray id={id} name={name} value={value} error={error} touched={touched} className={className} elements={["Oui", "Non"]} />
    );
};
