import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  FormSection,
  FormGroup,
  Checkbox,
  CheckboxGroup,
  RadioButtonGroupFromArray,
  RadioButtonGroupYesNo,
} from "../helpers/FormHelper";
import * as Yup from "yup";
import * as APIConfig from "../helpers/APIConfig";
import ErrorFocus from "../helpers/ErrorFocus";
import RUG, { DropArea } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";

const set_form_values = (type_formulaire) => {
  if (!localStorage.getItem("form_values"))
    return {
      type_formulaire:
        type_formulaire === "physique"
          ? "Observation physique"
          : "Observation sur Internet",
      type_observation: "Observation faite par le débitant",
      code_douane: "",
      nom_tabac: "",
      adresse_tabac: "",
      code_postal: "",
      email: "",
      telephone: "",
      premiere_observation: "",
      faits_repetes: "",

      personne_en_cause: "Personne physique",
      sexe_personne: "",
      nom_personne: "",
      prenom_personne: "",
      telephone_personne: "",
      adresse_personne: "",
      code_postal_personne: "",
      ville_personne: "",
      nom_societe: "",
      nom_gerant_societe: "",
      adresse_societe: "",
      code_postal_societe: "",
      ville_societe: "",
      moyen_transport: "",
      type_transport: "",
      plaque_immatriculation_transport: "",
      marque_transport: "",
      modele_transport: "",
      couleur_transport: "",

      produit_concerne: [],
      produit_marque: [],
      produit_marque_autre: "",
      produit_vente: [],
      vente_quantite: "",
      moyen_approvisionnement: "",
      moyen_approvisionnement_autre: "",
      methode_vente: "",
      methode_vente_autre: "",

      origine_fraude: "",
      origine_fraude_info: "",
      origine_fraude_info_autre_precision: "",

      email_vendeur: "",
      nom_vendeur: "",
      lien_compte_vendeur: "",

      moyen_delivrance: "",
      delai_livraison: "",
      moyen_delivrance_autre_precision: "",

      observations_complementaires: "",
      photos: [],
      date_creation: new Date().toISOString().slice(0, 10),
    };
  else {
    var stored_data = JSON.parse(localStorage.getItem("form_values"));
    stored_data.type_formulaire =
      type_formulaire === "physique"
        ? "Observation physique"
        : "Observation sur Internet";
    return stored_data;
  }
};

export class FormFicheTabac extends React.Component {
  render() {
    return (
      <Formik
        initialValues={set_form_values(this.props.type_formulaire)}
        validate={(values) => {
          localStorage.setItem("form_values", JSON.stringify(values));
          let errors = {};
          if (!values.email && !values.telephone) {
            errors.email =
              "Veuillez saisir votre email et/ou votre numéro de téléphone";
            errors.telephone = errors.email;
          }
          if (values.type_formulaire === "Observation physique") {
            if (values.personne_en_cause === "Personne physique") {
              if (
                !values.nom_personne &&
                !values.telephone_personne &&
                !values.adresse_personne &&
                !values.plaque_immatriculation_transport
              )
                errors.personne_en_cause =
                  "Veuillez préciser au moins un élément d'identification de la personne physique (nom, téléphone, adresse ou plaque d'immatriculation)";
              if (
                values.adresse_personne !== "" &&
                !values.code_postal_personne &&
                !values.ville_personne
              ) {
                errors.code_postal_personne =
                  "Veuillez préciser le code postal et/ou la ville";
                errors.ville_personne = errors.code_postal_personne;
              }
            } else if (values.personne_en_cause === "Personne morale") {
              if (
                !values.nom_societe &&
                !values.nom_gerant_societe &&
                !values.adresse_societe &&
                !values.plaque_immatriculation_transport
              )
                errors.personne_en_cause =
                  "Veuillez préciser au moins un élément d'identification de la personne morale (nom de la société, nom du gérant, adresse ou plaque d'immatriculation)";
              if (
                values.adresse_societe !== "" &&
                !values.code_postal_societe &&
                !values.ville_societe
              ) {
                errors.code_postal_societe =
                  "Veuillez préciser le code postal et/ou la ville";
                errors.ville_societe = errors.code_postal_societe;
              }
            } else if (
              values.personne_en_cause === "Véhicule (personne non connue)"
            ) {
              if (!values.plaque_immatriculation_transport)
                errors.personne_en_cause =
                  "Veuillez préciser la plaque d'immatriculation du véhicule";
            }
          }
          return errors;
        }}
        validationSchema={Yup.object().shape({
          code_douane: Yup.string().required(
            "Veuillez préciser le code douane de votre bureau de tabac"
          ),
          nom_gerant: Yup.string().required(
            "Veuillez préciser le nom du gérant de votre bureau de tabac"
          ),
          prenom_gerant: Yup.string().required(
            "Veuillez préciser le prénom du gérant de votre bureau de tabac"
          ),
          type_observation: Yup.string().required(
            "Veuillez préciser le type d'observation"
          ),
          nom_tabac: Yup.string().required(
            "Veuillez préciser le nom de votre bureau de tabac"
          ),
          adresse_tabac: Yup.string().required(
            "Veuillez préciser l'adresse de votre bureau de tabac"
          ),
          code_postal: Yup.string()
            .required("Veuillez préciser votre code postal")
            .matches(/^[0-9]{5}$/, "Code postal invalide"),
          email: Yup.string().email("Email invalide"),
          premiere_observation: Yup.string().required(
            "Veuillez préciser si c'est la première fois que vous constatez ces faits"
          ),
          faits_repetes: Yup.string().required(
            "Veuillez préciser si vous avez constaté ces faits à plusieurs reprises"
          ),
          code_postal_personne: Yup.string().matches(
            /^[0-9]{5}$/,
            "Code postal invalide"
          ),
          code_postal_societe: Yup.string().matches(
            /^[0-9]{5}$/,
            "Code postal invalide"
          ),
        })}
        onSubmit={(values, actions) => {
          if (!navigator.onLine) {
            alert(
              "Vous n'êtes pas connecté à Internet. Veuillez vous connecter puis cliquer à nouveau sur le bouton Envoyer pour transmettre votre fiche."
            );
          } else {
            this.props.onFormSubmit();
            //console.log(JSON.stringify(values, null, 2));

            fetch(APIConfig.API_URI + "fiches", {
              method: "POST",
              headers: APIConfig.HEADERS,
              body: JSON.stringify(values),
            })
              .then((response) => {
                //console.log(response);
                actions.setSubmitting(false);

                if (!response.ok) {
                  alert(
                    "Une erreur s'est produite lors de l'enregistrement de la fiche. Veuillez réessayer."
                  );
                } else {
                  //console.log('OK');
                  localStorage.removeItem("form_values");
                  this.props.onFormSubmitted();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
        }) => {
          const doAjax = async (url) => {
            const response = await fetch(url);
            if (response.ok) {
              const jsonValue = await response.json();
              return Promise.resolve(jsonValue);
            } else {
              return Promise.reject("error");
            }
          };

          const handleBlur = async (e) => {
            const codeDouanes = values.code_douane;
            const nom = e.target.value;

            if (!codeDouanes) {
              return;
            }

            doAjax(
              APIConfig.API_URI + "getDebitInfo?codeDouanes=" + codeDouanes
            )
              .then((response) => {
                const data = response.data;

                if (!response.success) {
                  setFieldError(
                    "nom_gerant",
                    "Il n'y a pas de correspondance entre le code douane et le nom du gérant."
                  );

                  return;
                }

                if (nom.toLowerCase() == data.gerant.nom.toLowerCase()) {
                  setFieldValue("nom_tabac", data.debit.enseigne);
                  setFieldValue(
                    "adresse_tabac",
                    data.debit.adresse.numero +
                      " " +
                      data.debit.adresse.type_voie +
                      " " +
                      data.debit.adresse.voie
                  );
                  setFieldValue("code_postal", data.debit.adresse.code_postal);
                  setFieldValue("telephone", data.debit.telephone);
                  setFieldValue("nom_gerant", data.gerant.nom);
                  setFieldValue("prenom_gerant", data.gerant.prenom);
                } else {
                  setFieldError(
                    "nom_gerant",
                    "Il n'y a pas de correspondance entre le code douane et le nom du gérant."
                  );
                }
              })
              .catch(console.error);
          };

          return (
            <Form id="formulaire_fiche_tabac">
              <FormSection
                label="Origine de l'observation"
                icon="icon_location"
              >
                <FormGroup
                  id="type_observation"
                  label="Type d'observation"
                  required
                >
                  <RadioButtonGroupFromArray
                    name="type_observation"
                    id="type_observation"
                    value={values.type_observation}
                    error={errors.type_observation}
                    touched={touched.type_observation}
                    elements={[
                      "Observation faite par le débitant",
                      "Information donnée au débitant par une personne tierce",
                    ]}
                    separator="line"
                  />
                </FormGroup>

                <p>
                  Veuillez saisir votre code douane et votre nom pour retrouver
                  toutes vos informations.
                </p>
                <FormGroup id="code_douane" label="Code Douane" required>
                  <Field
                    name="code_douane"
                    id="code_douane"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="code_douane"
                    component="div"
                    className="text-danger"
                    maxLength="5"
                  />
                </FormGroup>
                <FormGroup id="nom_gerant" label="Nom Gérant" required>
                  <Field
                    name="nom_gerant"
                    id="nom_gerant"
                    className="form-control"
                    onBlurCapture={handleBlur}
                  />
                  <ErrorMessage
                    name="nom_gerant"
                    component="div"
                    className="text-danger"
                    maxLength="5"
                  />
                </FormGroup>

                <FormGroup id="prenom_gerant" label="Prénom Gérant" required>
                  <Field
                    name="prenom_gerant"
                    id="prenom_gerant"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="prenom_gerant"
                    component="div"
                    className="text-danger"
                    maxLength="5"
                  />
                </FormGroup>

                <FormGroup id="nom_tabac" label="Nom du tabac" required>
                  <Field
                    name="nom_tabac"
                    id="nom_tabac"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="nom_tabac"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup id="adresse_tabac" label="Adresse du tabac" required>
                  <Field
                    name="adresse_tabac"
                    id="adresse_tabac"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="adresse_tabac"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup id="code_postal" label="Code postal" required>
                  <Field
                    name="code_postal"
                    id="code_postal"
                    className="form-control"
                    maxLength="5"
                  />
                  <ErrorMessage
                    name="code_postal"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup id="email" label="Email" required>
                  <Field name="email" id="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup id="telephone" label="Téléphone" required>
                  <Field
                    name="telephone"
                    id="telephone"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="telephone"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup id="deuxieme_telephone" label="Deuxième Téléphone">
                  <Field
                    name="deuxieme_telephone"
                    id="deuxieme_telephone"
                    className="form-control"
                  />
                </FormGroup>

                <FormGroup
                  id="premiere_observation"
                  label="Première observation ?"
                  required
                >
                  <RadioButtonGroupYesNo
                    name="premiere_observation"
                    id="premiere_observation"
                    value={values.premiere_observation}
                    error={errors.premiere_observation}
                    touched={touched.premiere_observation}
                  />
                </FormGroup>

                <FormGroup id="faits_repetes" label="Faits répetés ?" required>
                  <RadioButtonGroupYesNo
                    name="faits_repetes"
                    id="faits_repetes"
                    value={values.faits_repetes}
                    error={errors.faits_repetes}
                    touched={touched.faits_repetes}
                  />
                </FormGroup>
              </FormSection>

              {this.props.type_formulaire === "physique" && (
                <>
                  <FormSection
                    label="Identification"
                    icon="icon_identification"
                  >
                    <FormGroup id="personne_en_cause" label="Personne en cause">
                      <RadioButtonGroupFromArray
                        id="personne_en_cause"
                        name="personne_en_cause"
                        value={values.personne_en_cause}
                        error={errors.personne_en_cause}
                        touched={touched.personne_en_cause}
                        elements={[
                          "Personne physique",
                          "Personne morale",
                          "Véhicule (personne non connue)",
                        ]}
                      />
                    </FormGroup>

                    {values.personne_en_cause === "Personne physique" && (
                      <div>
                        <FormGroup id="personne_en_cause" label="Sexe">
                          <RadioButtonGroupFromArray
                            id="sexe_personne"
                            name="sexe_personne"
                            value={values.sexe_personne}
                            error={errors.sexe_personne}
                            touched={touched.sexe_personne}
                            elements={["Masculin", "Féminin"]}
                          />
                        </FormGroup>

                        <FormGroup id="nom_personne" label="Nom">
                          <Field
                            name="nom_personne"
                            id="nom_personne"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup id="prenom_personne" label="Prénom">
                          <Field
                            name="prenom_personne"
                            id="prenom_personne"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup id="telephone_personne" label="Téléphone">
                          <Field
                            name="telephone_personne"
                            id="telephone_personne"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup
                          id="adresse_personne"
                          label="Adresse complète de la personne"
                        >
                          <Field
                            name="adresse_personne"
                            id="adresse_personne"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup
                          id="code_postal_personne"
                          label="Code postal"
                        >
                          <Field
                            name="code_postal_personne"
                            id="code_postal_personne"
                            className="form-control"
                            maxLength="5"
                          />
                          <ErrorMessage
                            name="code_postal_personne"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>

                        <FormGroup id="ville_personne" label="Ville">
                          <Field
                            name="ville_personne"
                            id="ville_personne"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="ville_personne"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </div>
                    )}

                    {values.personne_en_cause === "Personne morale" && (
                      <div>
                        <FormGroup id="nom_societe" label="Nom de la société">
                          <Field
                            name="nom_societe"
                            id="nom_societe"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup
                          id="nom_gerant_societe"
                          label="Nom du gérant"
                        >
                          <Field
                            name="nom_gerant_societe"
                            id="nom_gerant_societe"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup
                          id="adresse_societe"
                          label="Adresse complète de la société"
                        >
                          <Field
                            name="adresse_societe"
                            id="adresse_societe"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup id="code_postal_societe" label="Code postal">
                          <Field
                            name="code_postal_societe"
                            id="code_postal_societe"
                            className="form-control"
                            maxLength="5"
                          />
                          <ErrorMessage
                            name="code_postal_societe"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>

                        <FormGroup id="ville_societe" label="Ville">
                          <Field
                            name="ville_societe"
                            id="ville_societe"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="ville_societe"
                            component="div"
                            className="text-danger"
                          />
                        </FormGroup>
                      </div>
                    )}

                    {(values.personne_en_cause === "Personne physique" ||
                      values.personne_en_cause === "Personne morale") && (
                      <FormGroup
                        id="moyen_transport"
                        label="Moyen de transport"
                      >
                        <RadioButtonGroupYesNo
                          name="moyen_transport"
                          id="moyen_transport"
                          value={values.moyen_transport}
                          error={errors.moyen_transport}
                          touched={touched.moyen_transport}
                        />
                      </FormGroup>
                    )}

                    {(values.moyen_transport === "Oui" ||
                      values.personne_en_cause ===
                        "Véhicule (personne non connue)") && (
                      <div>
                        <FormGroup id="type_transport" label="Type">
                          <Field
                            name="type_transport"
                            id="type_transport"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup
                          id="plaque_immatriculation_transport"
                          label="Plaque d'immatriculation"
                        >
                          <Field
                            name="plaque_immatriculation_transport"
                            id="plaque_immatriculation_transport"
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup id="marque_transport" label="Marque">
                          <Field
                            component="select"
                            name="marque_transport"
                            id="marque_transport"
                            className="form-control"
                          >
                            <option value="Renault">Renault</option>
                            <option value="Peugeot">Peugeot</option>
                            <option value="Citroen">Citroen</option>
                            <option value="Volkswagen">Volkswagen</option>
                            <option value="Alfa Romeo">Alfa Romeo</option>
                            <option value="Audi">Audi</option>
                            <option value="BMW">BMW</option>
                            <option value="Chevrolet">Chevrolet</option>
                            <option value="Dacia">Dacia</option>
                            <option value="Ferrari">Ferrari</option>
                            <option value="Fiat">Fiat</option>
                            <option value="Ford">Ford</option>
                            <option value="Honda">Honda</option>
                            <option value="Hyundai">Hyundai</option>
                            <option value="Infiniti">Infiniti</option>
                            <option value="Jaguar">Jaguar</option>
                            <option value="Jeep">Jeep</option>
                            <option value="Kia">Kia</option>
                            <option value="Lancia">Lancia</option>
                            <option value="Land Rover">Land Rover</option>
                            <option value="Lexus">Lexus</option>
                            <option value="Maserati">Maserati</option>
                            <option value="Mazda">Mazda</option>
                            <option value="Mercedes">Mercedes</option>
                            <option value="Mini">Mini</option>
                            <option value="Mitsubishi">Mitsubishi</option>
                            <option value="Nissan">Nissan</option>
                            <option value="Opel">Opel</option>
                            <option value="Porsche">Porsche</option>
                            <option value="Seat">Seat</option>
                            <option value="Skoda">Skoda</option>
                            <option value="Ssangyong">Ssangyong</option>
                            <option value="Subaru">Subaru</option>
                            <option value="Suzuki">Suzuki</option>
                            <option value="Tesla">Tesla</option>
                            <option value="Toyota">Toyota</option>
                            <option value="Volvo">Volvo</option>
                          </Field>
                        </FormGroup>

                        <FormGroup id="modele_transport" label="Modèle">
                          <Field
                            name="modele_transport"
                            id="modele_transport"
                            placeholder="5008, C3, Qashkai, Ibiza ..."
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup id="couleur_transport" label="Couleur">
                          <Field
                            name="couleur_transport"
                            id="couleur_transport"
                            placeholder="Bleu, rouge, noir ..."
                            className="form-control"
                          />
                        </FormGroup>
                      </div>
                    )}
                  </FormSection>

                  <FormSection
                    label="Marchandise / Produit"
                    icon="icon_marchandises"
                  >
                    <FormGroup id="produit_concerne" label="Produit concerné">
                      <CheckboxGroup
                        id="produit_concerne"
                        value={values.produit_concerne}
                        error={errors.produit_concerne}
                        touched={touched.produit_concerne}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      >
                        <Field
                          component={Checkbox}
                          name="produit_concerne"
                          id="Cigarettes"
                          label="Cigarettes"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_concerne"
                          id="Cigarillos"
                          label="Cigarillos"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_concerne"
                          id="Tabac à chicha"
                          label="Tabac à chicha"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_concerne"
                          id="Cigares"
                          label="Cigares"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_concerne"
                          id="Tabac à rouler"
                          label="Tabac à rouler"
                        />
                      </CheckboxGroup>
                    </FormGroup>

                    <FormGroup id="produit_marque" label="Marque">
                      <CheckboxGroup
                        id="produit_marque"
                        value={values.produit_marque}
                        error={errors.produit_marque}
                        touched={touched.produit_marque}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      >
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Camel"
                          label="Camel"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Chesterfield"
                          label="Chesterfield"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Lucky Strike"
                          label="Lucky Strike"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Marlboro"
                          label="Marlboro"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="News"
                          label="News"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Phillip Morris"
                          label="Phillip Morris"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Winston"
                          label="Winston"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_marque"
                          id="Autre"
                          label="Autre"
                        />
                      </CheckboxGroup>
                      {values.produit_marque.includes("Autre") && (
                        <>
                          <label htmlFor="produit_marque_autre">
                            Précisez:{" "}
                          </label>{" "}
                          <Field
                            name="produit_marque_autre"
                            id="produit_marque_autre"
                            className="form-control"
                          />
                        </>
                      )}
                    </FormGroup>

                    <FormGroup id="produit_vente" label="Vente">
                      <CheckboxGroup
                        id="produit_vente"
                        value={values.produit_vente}
                        error={errors.produit_vente}
                        touched={touched.produit_vente}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      >
                        <Field
                          component={Checkbox}
                          name="produit_vente"
                          id="Par paquet"
                          label="Par paquet"
                        />
                        <Field
                          component={Checkbox}
                          name="produit_vente"
                          id="Par cartouche"
                          label="Par cartouche"
                        />
                      </CheckboxGroup>
                    </FormGroup>

                    <FormGroup
                      id="vente_quantite"
                      label="Quantité approximative"
                    >
                      <Field
                        name="vente_quantite"
                        id="vente_quantite"
                        className="form-control"
                      />
                    </FormGroup>
                  </FormSection>

                  <FormSection
                    label="Moyen d'approvisionnement"
                    icon="icon_truck"
                  >
                    <FormGroup id="moyen_approvisionnement" label="">
                      <RadioButtonGroupFromArray
                        id="moyen_approvisionnement"
                        name="moyen_approvisionnement"
                        value={values.moyen_approvisionnement}
                        error={errors.moyen_approvisionnement}
                        touched={touched.moyen_approvisionnement}
                        elements={[
                          "Avion",
                          "Bateau",
                          "Route",
                          "Train",
                          "Autre",
                        ]}
                      />
                      {values.moyen_approvisionnement === "Autre" && (
                        <>
                          <label htmlFor="moyen_approvisionnement_autre">
                            Précisez:{" "}
                          </label>{" "}
                          <Field
                            name="moyen_approvisionnement_autre"
                            id="moyen_approvisionnement_autre"
                            className="form-control"
                          />
                        </>
                      )}
                    </FormGroup>
                  </FormSection>

                  <FormSection label="Méthode de vente" icon="icon_city">
                    <FormGroup id="methode_vente" label="">
                      <RadioButtonGroupFromArray
                        id="methode_vente"
                        name="methode_vente"
                        value={values.methode_vente}
                        error={errors.methode_vente}
                        touched={touched.methode_vente}
                        elements={[
                          "Dans la rue",
                          "Dans un lieu privé",
                          "Dans un lieu public",
                          "Fret postal",
                          "Par téléphone",
                          "Autre",
                        ]}
                      />
                      {values.methode_vente === "Autre" && (
                        <>
                          <label htmlFor="methode_vente_autre">
                            Précisez:{" "}
                          </label>{" "}
                          <Field
                            name="methode_vente_autre"
                            id="methode_vente_autre"
                            className="form-control"
                          />
                        </>
                      )}
                    </FormGroup>
                  </FormSection>
                </>
              )}

              {this.props.type_formulaire === "internet" && (
                <>
                  <FormSection
                    label="Origine de la fraude"
                    icon="icon_internet"
                  >
                    <FormGroup id="origine_fraude" label="">
                      <RadioButtonGroupFromArray
                        id="origine_fraude"
                        name="origine_fraude"
                        value={values.origine_fraude}
                        error={errors.origine_fraude}
                        touched={touched.origine_fraude}
                        elements={[
                          "Réseaux sociaux",
                          "Messagerie instantanée",
                          "Site Internet",
                        ]}
                      />

                      {(values.origine_fraude === "Réseaux sociaux" ||
                        values.origine_fraude === "Messagerie instantanée") && (
                        <>
                          <br />
                          <RadioButtonGroupFromArray
                            id="origine_fraude_info"
                            name="origine_fraude_info"
                            value={values.origine_fraude_info}
                            error={errors.origine_fraude_info}
                            touched={touched.origine_fraude_info}
                            elements={
                              values.origine_fraude === "Réseaux sociaux"
                                ? [
                                    "Facebook",
                                    "Twitter",
                                    "Snapchat",
                                    "Instagram",
                                    "Flickr",
                                    "Autre",
                                  ]
                                : [
                                    "Facebook Messenger",
                                    "WhatsApp",
                                    "Signal",
                                    "Viber",
                                    "Telegram",
                                    "Discord",
                                    "Autre",
                                  ]
                            }
                          />
                          {values.origine_fraude_info === "Autre" && (
                            <>
                              <label htmlFor="origine_fraude_info_autre_precision">
                                Précisez:{" "}
                              </label>{" "}
                              <Field
                                name="origine_fraude_info_autre_precision"
                                id="origine_fraude_info_autre_precision"
                                className="form-control"
                              />
                            </>
                          )}
                        </>
                      )}

                      {values.origine_fraude === "Site Internet" && (
                        <>
                          <label htmlFor="origine_fraude_info_autre_precision">
                            Précisez l'adresse exacte:{" "}
                          </label>{" "}
                          <Field
                            name="origine_fraude_info_autre_precision"
                            id="origine_fraude_info_autre_precision"
                            className="form-control"
                          />
                        </>
                      )}
                    </FormGroup>
                  </FormSection>

                  <FormSection
                    label="Informations sur le vendeur"
                    icon="icon_identification"
                  >
                    <div>
                      <FormGroup
                        id="email_vendeur"
                        label="Adresse email de la personne"
                      >
                        <Field
                          name="email_vendeur"
                          id="email_vendeur"
                          className="form-control"
                        />
                      </FormGroup>

                      <FormGroup id="nom_vendeur" label="Nom, prénom ou pseudo">
                        <Field
                          name="nom_vendeur"
                          id="nom_vendeur"
                          className="form-control"
                        />
                      </FormGroup>

                      <FormGroup
                        id="lien_compte_vendeur"
                        label="Lien vers le compte"
                      >
                        <Field
                          name="lien_compte_vendeur"
                          id="lien_compte_vendeur"
                          className="form-control"
                        />
                      </FormGroup>
                    </div>
                  </FormSection>

                  <FormSection label="Moyen de délivrance" icon="icon_truck">
                    <div>
                      <FormGroup id="moyen_delivrance" label="">
                        <RadioButtonGroupFromArray
                          id="moyen_delivrance"
                          name="moyen_delivrance"
                          value={values.moyen_delivrance}
                          error={errors.moyen_delivrance}
                          touched={touched.moyen_delivrance}
                          elements={[
                            "Livraison postale",
                            "Fret express",
                            "Livraison en main propre",
                            "Fret routier",
                            "Autre",
                          ]}
                        />
                        {values.moyen_delivrance === "Autre" && (
                          <>
                            <label htmlFor="moyen_delivrance_autre_precision">
                              Précisez:{" "}
                            </label>{" "}
                            <Field
                              name="moyen_delivrance_autre_precision"
                              id="moyen_delivrance_autre_precision"
                              className="form-control"
                            />
                          </>
                        )}
                      </FormGroup>
                      {values.moyen_delivrance === "Livraison postale" && (
                        <FormGroup
                          id="delai_livraison"
                          label="Délai de livraison"
                        >
                          <RadioButtonGroupFromArray
                            id="delai_livraison"
                            name="delai_livraison"
                            value={values.delai_livraison}
                            error={errors.delai_livraison}
                            touched={touched.delai_livraison}
                            elements={[
                              "Moins de 72h",
                              "Entre 72h et 7 jours",
                              "Supérieur à 7 jours",
                            ]}
                          />
                        </FormGroup>
                      )}
                    </div>
                  </FormSection>
                </>
              )}

              <FormSection
                label="Observations complémentaires"
                icon="icon_observations"
              >
                <Field
                  component="textarea"
                  id="observations_complementaires"
                  name="observations_complementaires"
                  className="form-control"
                  rows="10"
                />
              </FormSection>

              <FormSection label="Photos" icon="icon_photos">
                <RUG
                  action={
                    APIConfig.API_URI +
                    "containers/" +
                    APIConfig.PHOTOS_CONTAINER +
                    "/upload"
                  }
                  source={(response) => response.result}
                  header={({ openDialogue }) => (
                    <DropArea>
                      {(isDrag) => (
                        <div>
                          <Button
                            onClick={openDialogue}
                            className="add_photos_button"
                          >
                            Ajouter des photos
                          </Button>
                        </div>
                      )}
                    </DropArea>
                  )}
                  onChange={(images) => {
                    values.photos = images;
                  }}
                />
              </FormSection>
              <ErrorFocus />
              <div className="text-center">
                <Link to="/">
                  <Button variant="outline-dark">Retour</Button>
                </Link>
                &nbsp;&nbsp;&nbsp;
                <Button
                  type="submit"
                  className="submit_button"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Envoyer la fiche
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
