import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const ModalInformations = (props) => (
    <Modal {...props} size="lg" className="modal_informations">
        <Modal.Header closeButton>
            <Modal.Title>Présentation de la fiche « STOP TRAFIC TABAC » dématérialisée</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Afin de renforcer la lutte contre les trafics de tabac, la Confédération met à votre disposition cette application 
            « STOP TRAFIC TABAC », développée avec le concours de la douane, pour vous permettre de signaler à la douane, en toute confidentialité, tout trafic 
            de tabacs que vous pourriez remarquer ou dont vous pourriez être informé.<br />
            <br />
            La fiche « STOP TRAFIC TABAC » est à l'<u>usage exclusif des buralistes, et présente un caractère de confidentialité 
            et de discrétion absolu</u>. Elle est reçue et traitée uniquement par des agents de la DGDDI.<br />
            <br />
            - Il convient de la remplir avec le plus grand soin afin <u>de transmettre une information exploitable par les services 
            de la DGDDI</u>.<br />
            <br />
            - <u>Chaque fiche est automatiquement transmise au service local des douanes territorialement compétent ainsi qu’aux 
            services centraux douaniers</u>.<br />
            <br />
            - Des informations transmises, qui se révèleraient inexactes ou erronées, ne sauraient engager votre responsabilité.<br />
            <br />  
            - Si la fiche « STOP TRAFIC TABAC »  aboutit à des résultats concluants, vous serez informé par le service local 
            compétent (avec information de la Confédération), dans le respect de la confidentialité inhérente aux enquêtes 
            douanières et à leurs éventuelles suites judiciaires.<br />
            <br />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>Fermer</Button>
        </Modal.Footer>
    </Modal>
);