import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

export const Template = props => (
        <div id="page">
            <div id="page_inner">
                <nav id="header">
                    <Link to="/" className="logo"><Image src="/images/logo.png" /></Link>
                    <div className="mention">CONFIDENTIEL</div>
                </nav>
                <section id="content">
                    {props.children}
                </section> 
            </div>
        </div>
);