import React from 'react';
import { Redirect } from 'react-router-dom';

export const FormResult = (props) => (
    <div className="text-center my-5 lead">
        {props.progress === 'progress' ?
            <div>
                <img src="images/loader.gif" alt="" width="100" /><br />
                Enregistrement de la fiche en cours ...
            </div>
        :

        props.progress === 'end' ?
            <div>
                Votre fiche a bien été enregistrée.<br />
                Merci de votre participation.
            </div>
            
        :    
            <Redirect to="/" />  
        }
    </div>
)