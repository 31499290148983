import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';

import { Template } from './views/Template';
import { Home } from './views/Home';
import { FormFicheTabac } from './views/FormFicheTabac';
import { FormResult } from './views/FormResult';



class AppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            type_formulaire : 'physique',
            form_submit_progress: ''
        };
    }
    
    selectForm = type => {
        this.setState({ type_formulaire : type }, () => {
            this.props.history.push('/formulaire');
        });
    }
    
    formSubmit = () => {
        this.setState({ form_submit_progress : 'progress' }, () => {
            this.props.history.push('/fin'); 
        });
    }
    
    formSubmitted = () => {
        this.setState({ form_submit_progress : 'end' }); 
    }
    
    render = () => {
        return (
            <div id="App" className="App">
                <Switch>
                    <Route exact path="/" render={() => (
                        <Home selectForm={this.selectForm} />
                    )} />
                    <Route render={() => (
                        <Template>
                            <Switch>    
                                <Route path="/formulaire" render={() => (
                                    <FormFicheTabac type_formulaire={this.state.type_formulaire} onFormSubmit={this.formSubmit} onFormSubmitted={this.formSubmitted} />
                                )} />
                                <Route path="/fin" render={() => (
                                    <FormResult progress={this.state.form_submit_progress} />
                                )} />
                            </Switch>
                        </Template>
                    )} />

                </Switch>
            </div>
        );
    };
};

const App = () => (
    <Router>
        <Route component={AppRouter} />
    </Router>
);
export default App;
    